// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-graduate-js": () => import("./../src/templates/graduate.js" /* webpackChunkName: "component---src-templates-graduate-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-graduates-js": () => import("./../src/pages/graduates.js" /* webpackChunkName: "component---src-pages-graduates-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sponsors-js": () => import("./../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

